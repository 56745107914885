import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import TVIllustration from "../../../../svg/ComparisonResultPageIllustrations/tvdeals.svg";
import ATandTSVG from "../../../../svg/ComparisonResultGridLogos/tvpackages/att.svg";
import PhiloSVG from "../../../../svg/ComparisonResultGridLogos/tvpackages/philo.svg";
import DishTVSVG from "../../../../svg/ComparisonResultGridLogos/tvpackages/dish.svg";
import SuddenLinkSVG from "../../../../svg/ComparisonResultGridLogos/tvpackages/suddenlink.svg";
import FuboTVSVG from "../../../../svg/ComparisonResultGridLogos/tvpackages/fubotv.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultHeroUserLocation,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  TVIllustration,
  ATandTSVG,
  PhiloSVG,
  DishTVSVG,
  SuddenLinkSVG,
  FuboTVSVG,
  React
};