import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import VoIPIllustration from "../../../../svg/ComparisonResultPageIllustrations/voipservices.svg";
import OomaSVG from "../../../../svg/ComparisonResultGridLogos/voipservices/ooma.svg";
import GoToMeetingSVG from "../../../../svg/ComparisonResultGridLogos/voipservices/gotomeeting.svg";
import PhoneDotComSVG from "../../../../svg/ComparisonResultGridLogos/voipservices/phonedotcom.svg";
import MightyCallSVG from "../../../../svg/ComparisonResultGridLogos/voipservices/mightycall.svg";
import EightByEightSVG from "../../../../svg/ComparisonResultGridLogos/voipservices/eightbyeight.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  VoIPIllustration,
  OomaSVG,
  GoToMeetingSVG,
  PhoneDotComSVG,
  MightyCallSVG,
  EightByEightSVG,
  React
};